import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import SvgIconsSet from "../../components/SvgIconsSet";

const Impact = () => {
  const data = useStaticQuery(graphql`
    query {
      JobOpen: file(relativePath: { eq: "Internship.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 385, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="section-impact">
      <div className="section-impact-left">
        <div className="section-impact-left-dec-title">
        Ready to make an impact?        </div>
        <div className="page-careers-dec">
        Get in touch for a free, no-obligation consultation with one of our experts
        </div>
        <a href="/contact" class="mt-2 btn btn-primary btn-icon-button">
          <span className="btn-text">Contact Us</span>
          <span className="btn-icon">
            <SvgIconsSet.SideArrow />
          </span>
        </a>
      </div>

      <div className="section-impact-right">
        <div className="section-impact-img">
          <Img
            fluid={data.JobOpen.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Opening Jobs"
          />
        </div>
      </div>
    </div>
  );
};

export default Impact;

import React from "react";
import SalesforceLogo from "../images/partners/salesforce.png";

import wso2Logo from "../images/partners/wso2.png";
import symboxLogo from "../images/partners/Symbox.png";
import bizLogo from "../images/partners/bioxag.png";
import powerLogo from "../images/partners/powerapps.png";
import { Link } from "gatsby";
import ModalAws from "../pages/partners/modals/ModalAws";
import ModalSalef from "../pages/partners/modals/ModalSaleforce";
import ModalWso from "../pages/partners/modals/ModalWso";
import ModalSym from "../pages/partners/modals/ModalSymbox";
import ModalIBM from "../pages/partners/modals/ModalIBM";
import ModalPower from "../pages/partners/modals/ModalPower";
import ModalMongoDB from "../pages/partners/modals/ModalMongoDB";
import ModalISTQB from "../pages/partners/modals/ModalISTQB";
import ModalFinTech from "../pages/partners/modals/ModalFinTech";

const Partners = () => {
  return (
    <section className="partner-grp py-sm-3 py-0">
      <div>
        <div className="container mt-3">
          <div className="row mb-5">
            <div className="col">
              <p className=""> iTelaSoft is working with a strong partner ecosystem and we
              believe that’s a vital element in our business to add the best
              value to our customers. With a deep relationship with partner
              product managers and technical experts, we support our customers
              in their complex business needs and mission critical operations.
              Also, we provide the advantages of bleeding edge technology from
              our partner capabilities. </p>
            </div>
          </div>

          <div className="row">
            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalAws />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalSalef />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalWso />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalSym />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalMongoDB />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners space-p">
                <ModalISTQB />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners pt-1">
              <ModalIBM />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners pt-1">
              <ModalFinTech />
              </div>
            </div>

            {/* <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners pt-1">
              <ModalBiz />
              </div>
            </div>

            <div className="col-p col-12 col-lg-4 col-md-6">
              <div className="card-deck card-deck-partners pt-1">
              <ModalPower />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;

import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/ibm.png";

const bizagi = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>IBM</h3>
            <div className="lbl-ptnrn">Service Providers</div>
            <div>
              <a href="#">Visit Website</a>{" "}
              <span>
                
              </span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About IBM</h4>
            <p>
            IBM is globally renowned for its expertise in hybrid cloud and artificial intelligence solutions, offering unparalleled consulting services to clients in over 175 countries. With a focus on helping organizations unlock insights from their data, streamline business processes, and gain a competitive edge, IBM stands as a trusted partner for over 4,000 government and corporate entities worldwide. From critical infrastructure sectors like financial services, telecommunications, and healthcare, organizations rely on IBM's hybrid cloud platform and Red Hat OpenShift to drive rapid, efficient, and secure digital transformations. IBM's commitment to innovation in AI, quantum computing, and industry-specific cloud solutions, combined with its dedication to trust, transparency, responsibility, inclusivity, and service, ensures clients have access to cutting-edge technologies and tailored solutions to meet their evolving needs. As an esteemed partner of IBM, we are proud to showcase IBM's impactful contributions to the global business landscape.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Business Process Automation</div>
            {/* <div className="p"><span className="vdivide">-{" "}</span>IOT Machine</div>
            <div className="p"><span className="vdivide">-{" "}</span>Machine Learning</div> */}
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>Sri Lanka</p>
            {/* <p>Country 1 <span className="vdivide">|</span> Country 2 <span className="vdivide">|</span> Country 3 <span className="vdivide">|</span> Country 4</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default bizagi;

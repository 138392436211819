import React from "react";
import Layout from "../../layouts/index";
import Seo from "../../components/Seo";
import { Container } from "reactstrap";
import ContrastSection from "../../components/ContrastSection";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import PartnersCat from "../../components/Partnersuk";
import Impact from "../../components/homePage/Impact";

const Partners = (props) => {
  return (
    <Layout>
      <Seo
        title="Partners"
        description="Our partners deliver proven services, solutions, and technologies to customers worldwide."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
          />
        </div>
        <div className="container">
          <div className="row main-page">
            <div className="col-12">
              <h1 className="page-title">Partners</h1>
              <h2 className="page-subtitle">
                Our partners deliver validated services, solutions, and
                technologies to customers worldwide.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <ContrastSection
        title="Our strong strategic partnerships ensure our clients can always access the best solutions available"
        para={["A vital element of our business is the strong partner ecosystem we have cultivated over the years and the deep relationships we’ve fostered with product managers and technical experts.", <br/>, <br/>,
        "Solving complex business needs and mission critical operations requires the right tools and the right know-how, and we combine both to ensure our clients benefit from cutting edge technological solutions via our partner capabilities."]}
      />
      <PartnersCat />
      <Container className="mb-3">
        <Impact />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "partners-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Partners;

// import React from "react";
import MongoLogo from "../../../images/partners/mongodb.png";
import Modalp from "../../../components/modal";
import React, { useState } from "react";
import CardMod from "../partnerCards/mongodb";

const ModalAws = () => {
  const [task1Show, setTask1Show] = useState(false);
  return (
    <div className="card">
      <button
        className="click-modal"
        onClick={() => setTask1Show(true)}
      ></button>
      <div>
        <div className="img-container">
          <img className="img-fluid" src={MongoLogo} alt="PartnerImage" />
        </div>
        <div className="card-body">
          <div className="title">Mongo DB</div>
          {/* <div className="sub-title">API & Integration <span className="vdivide">|</span> CIAM</div> */}
          <div className="sub-title">Document-Oriented Database <span className="vdivide">|</span> Fully Managed <span className="vdivide">|</span> Multi-Cloud Developer Data Platform</div>

          <div className="btn-wrap">
          <div className="lbl-ptnrn">MongoDB Partner</div>
            <Modalp
              show={task1Show}
              handleClose={() => setTask1Show(false)}
              handleShow={() => setTask1Show(true)}
              modalTitle="Providers"
            >
              <div>
                <CardMod />
              </div>
            </Modalp>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAws;

// import React from "react";
import AwsLogo from "../../../images/partners/powerapps.png";
import Modalp from "../../../components/modal";
import React, { useState } from "react";
import Cardaws from "../partnerCards/powerapp";

const ModalAws = () => {
  const [task1Show, setTask1Show] = useState(false);
  return (
    <>
      <div className="card">
        <button className="click-modal" onClick={() => setTask1Show(true)}></button>
        <div >
          <img className="img-fluid" src={AwsLogo} alt="PartnerImage" />
          <div className="card-body">
            <div className="title">Amazon Web Services</div>
            <div className="sub-title">API & Integration <span className="vdivide"><span className="vdivide">|</span></span> CIAM</div>
            <div className="btn-wrap">
              <div className="lbl-ptnrn">Service Providers</div>
              <Modalp
                show={task1Show}
                handleClose={() => setTask1Show(false)}
                handleShow={() => setTask1Show(true)}
                // modalTitle="Providers"
              >
                <div>
                  <Cardaws />
                </div>
              </Modalp>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAws;

// import React from "react";
import Logo from "../../../images/partners/ibm.png";
import Modalp from "../../../components/modal";
import React, { useState } from "react";
import Cardibm from "../partnerCards/ibm";

const ModalAws = () => {
  const [task1Show, setTask1Show] = useState(false);
  return (
    <>
      <div className="card">
        <button className="click-modal" onClick={() => setTask1Show(true)}></button>
        <div>
        <div className="img-container">
          <img className="img-fluid" src={Logo} alt="PartnerImage" />
        </div>
          <div className="card-body">
            <div className="title">IBM</div>
            <div className="sub-title">API & Integration <span className="vdivide">|</span> CIAM</div>
            <div className="btn-wrap">
              <div className="lbl-ptnrn">Service Providers</div>
              <Modalp
                show={task1Show}
                handleClose={() => setTask1Show(false)}
                handleShow={() => setTask1Show(true)}
                // modalTitle="Providers"
              >
                <div>
                  <Cardibm />
                </div>
              </Modalp>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAws;

import React from "react";
import { Row, Col } from "react-bootstrap";
import MongoLogo from "../../../images/partners/mongodb.png";

const aws = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={MongoLogo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>Mongo DB</h3>
            <div className="lbl-ptnrn">MongoDB Partner</div>
            <div>
              <a href="https://www.mongodb.com/" target="_blank">
                Visit Website
              </a>{" "}
              <span></span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About Mongo DB</h4>
            <p>
              MongoDB is a multi-cloud developer data platform which is an
              integrated suite of cloud database and data services to accelerate
              and simplify how you build with data. It’s a document-oriented
              database that is designed to store a large scale of data and
              allows you to work with that data very efficiently. MongoDB
              extends the flexibility and ease of use to building full-text
              search, real-time analytics, and event-driven experiences. Build a
              wide range of application services while keeping your data
              architecture simple.
            </p>
            <p>
              iTelasoft works closely with MongoDB team to provide the best
              possible data solutions to your clients.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p">
              <span className="vdivide">- </span>Data Solutions tailored for the business need
            </div>
            {/* <div className="p">IOT Machine</div>
            <div className="p">Machine Learning</div> */}
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>

            <p>
              Australia <span className="vdivide">|</span> Singapore{" "}
              <span className="vdivide">|</span> UK{" "}
              <span className="vdivide">|</span> Africa <span className="vdivide">|</span> EMEA
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default aws;
